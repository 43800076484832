import { lazy } from "react";

import ContactContent from "../content/ContactContent.json";


const Contact = lazy(() => import("../ContactForm"));

const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../ContentBlock"));
const ContentBlockApp = lazy(() => import("../ContentBlockApp"));
const Home = () => {
  return (
    <Container>
      <ScrollToTop />
     
     
      <ContentBlockApp
        direction="left"
       
        icon="graphs.svg"
        id="app"
      />
      <ContentBlock
        direction="left"
       
        icon="graphs.svg"
        id="about"
      />
   
      <Contact
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
  );
};

export default Home;
