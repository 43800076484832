import { useState, useContext } from "react";
import { Row, Col, Drawer } from "antd";
import { DayAndNightToggle } from "../DayAndNightToggle";
import Container from "../common/Container";
import { SvgIcon } from "../common/SvgIcon";
import { Button } from "../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { AppContext } from "../Context/AppContext";

const Header = () => {
  const [visible, setVisibility] = useState(false);
  const { isDarkMode, setIsDarkMode } = useContext(AppContext);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{"About"}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{"Contact"}</Button>
          </Span>
        </CustomNavLinkSmall>
        
      
        
      </div>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img src="logo.png" height="104px" />
          </LogoContainer>
     
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
          
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
